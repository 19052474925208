import useAxios from 'hooks/useAxios';
import React, { useEffect, useState } from 'react';
// Import Context
import { Formiz, FormizStep, useForm } from '@formiz/core';
import { SearchWrapper, useSeachContext } from 'context/SearchContext';
import CambioEstadoSeguimiento from './CambioDeEstadoSeguimiento';
import SeguimientoActividades from './SeguimientoDeActividades';
import SeguimientoCitas from './SeguimientoDeCitas';
import SeguimientoNqr from './SeguimientoNqr';
// Import Hooks
import { useParams } from 'react-router';
// Import Services
import dateFormat from 'dateformat';
import useLangv2 from 'hooks/useLangv2';
import endpoints from 'services/api';
import SeguimientoProductos from './SeguimientoProductos';

const Trazabilidad = () => (
	<SearchWrapper>
		<TrazabilidadComponent />
	</SearchWrapper>
);
const TrazabilidadComponent = () => {
	const { RequestUseCase } = useAxios();
	const myForm = useForm();
	// activity ids
	const { id } = useParams();
	const { setDataTable } = useSeachContext();
	const [step, setStep] = useState();
	const { formatterText } = useLangv2();

	useEffect(() => {
		getDataTable(step);
	}, []);

	useEffect(() => {
		getDataTable(step);
	}, [id]);

	const getDataTable = (stepName) => {
		switch (stepName) {
			case 'step1':
				RequestUseCase.get(endpoints.services.getTrazabilidad(id))
					.then((data) => {
						console.log(data);
						const datos = StructureItemsStates(data);

						setDataTable(datos);
					})
					.catch((err) => {
						console.log(err);
					});
				break;
			case 'step2':
				RequestUseCase.get(endpoints.services.getTrazabilidadActividades(id))
					.then((data) => {
						const datos = StructureItemsActivities(data);
						setDataTable(datos);
					})
					.catch((err) => {
						console.log(err);
					});
				break;
			case 'step3':
				RequestUseCase.get(endpoints.services.getTrazabilidadCitas(id))
					.then((data) => {
						const datos = StructureItemsAppointment(data);
						setDataTable(datos);
					})
					.catch((err) => {
						console.log(err);
					});
				break;
			case 'step4':
				RequestUseCase.get(endpoints.services.getTrazabilidadNqrByIdService(id))
					.then((data) => {
						const datos = StructureItemsnQR(data);
						setDataTable(datos);
					})
					.catch((err) => {
						console.log(err);
					});
				break;
			case 'step5':
				RequestUseCase.get(endpoints.serviceProduct.getTrazabilidadProductsByIdService(id))
					.then((data) => {
						const datos = StructureItemsnProducts(data);
						setDataTable(datos);
					})
					.catch((err) => {
						console.log(err);
					});
				break;
		}
	};

	const StructureItemsStates = (data) => {
		const hashDesition = {
			null: 'Asesor'
		};

		const hashTech = {
			1: 'Tecnico Principal',
			0: 'Tecnico Auxiliar'
		};

		const DeterminaType = data.map((item) => {
			return {
				...item,
				actor: hashDesition[item.idTecnico] || hashTech[item.principal],
				isTech: !(hashDesition[item.idTecnico] || hashTech[item.principal] === 'Asesor')
			};
		});
		console.log('DeterminaType', DeterminaType);
		const FinalData = DeterminaType.map((item) => {
			return {
				actor: item.actor,
				name: item.isTech
					? item.idTecnico.idEmpleado.primerNombre !== undefined
						? `${item.idTecnico.idEmpleado.primerNombre} ${item.idTecnico.idEmpleado.primerApellido} `
						: `${item.idTecnico.idTercero.nombre}`
					: 'Asesor',
				state: item.idEstadoServicio.nombre,
				date:
					item.fechaCreacion.split('T')[0] + ' ' + item.fechaCreacion.split('T')[1].split('.')[0]
			};
		});

		console.log('FinalData', FinalData);

		return FinalData;
	};

	const StructureItemsActivities = (data) => {
		const timeZone = 'America/Bogota';

		const FinalData = data.map((item) => {
			return {
				name: item.idActividad.nombre,
				state: item.idEstadoActividad.nombre,
				date: dateFormat(item.fechaCreacion, 'yyyy/mm/dd - HH:MM TT', { timeZone })
			};
		});

		return FinalData;
	};

	const StructureItemsAppointment = (data) => {
		const FinalData = data.map((item) => {
			return {
				name:
					item.idTecnico.idEmpleado.primerNombre !== undefined
						? `${item.idTecnico.idEmpleado.primerNombre} ${item.idTecnico.idEmpleado.primerApellido} `
						: `${item.idTecnico.idTercero.nombre}`,
				state: item.fechaInicio.split('T')[0],

				date: item.idHoraInicio.hora,
				a: item.fechaFin.split('T')[0].split('T')[0],
				date2: item.idHoraFin.hora
			};
		});

		console.log('FinalData', FinalData);

		return FinalData;
	};

	const StructureItemsnQR = (data) => {
		const FinalData = data.map((item) => {
			const timeZone = 'America/Bogota';
			return {
				idNQR: item.idNqrServicio,
				description: item.descripcion,
				userCreation: item.nombreUsuario,
				state: item.idEstadoNqr.descripcion,
				modificationDate: dateFormat(item.fechaCreacion, 'yyyy/mm/dd - HH:MM TT', {timeZone})
			};
		});

		return FinalData;
	};

	const StructureItemsnProducts = (data) => {
		const FinalData = data.map((item) => {
			return {
				producto: item.nombreProducto ? item.nombreProducto : 'N/A',
				userCreation: item.usuarioCreacion.usuario,
				accion:
					item.gestionado == 1 ? formatterText('data.table.yes') : formatterText('data.table.no'),
				modificationDate: dateFormat(item.fechaRegistro, 'yyyy/mm/dd - HH:MM TT')
			};
		});

		return FinalData;
	};

	return (
		<div>
			<div className="tdR thR table-container">
				<Formiz connect={myForm}>
					<div
						className="new-container-wrapForm__tabs"
						style={{
							overflow: 'auto'
						}}
					>
						{myForm.steps.map((step) => (
							<button
								key={step.name}
								className={`tab-options-tables ${
									step.name === myForm.currentStep.name ? 'is-active' : ''
								}`}
								type="button"
								onClick={() => {
									myForm.goToStep(step.name);
									getDataTable(step.name);
									setStep(step.name);
								}}
							>
								{formatterText(step.label)}
							</button>
						))}
					</div>

					<FormizStep
						style={{
							overflow: 'auto',
							marginTop: '1rem'
						}}
						name="step1"
						label="title.service.statusChanges"
					>
						<CambioEstadoSeguimiento getDataTable={getDataTable} />
					</FormizStep>
					<FormizStep
						style={{
							overflow: 'auto',
							marginTop: '1rem'
						}}
						name="step2"
						label="title.service.activityTracking"
					>
						<SeguimientoActividades getDataTable={getDataTable} />
					</FormizStep>
					<FormizStep
						style={{
							overflow: 'auto',
							marginTop: '1rem'
						}}
						name="step3"
						label="title.service.appointmentTracking"
					>
						<SeguimientoCitas getDataTable={getDataTable} />
					</FormizStep>
					<FormizStep
						style={{
							overflow: 'auto',
							marginTop: '1rem'
						}}
						name="step4"
						label="title.service.nqrTracking"
					>
						<SeguimientoNqr getDataTable={getDataTable} name="step4" />
					</FormizStep>
					<FormizStep
						style={{
							overflow: 'auto',
							marginTop: '1rem'
						}}
						name="step5"
						label="title.service.products.tracking"
					>
						<SeguimientoProductos getDataTable={getDataTable} name="step5" />
					</FormizStep>
				</Formiz>
			</div>
		</div>
	);
};

export default Trazabilidad;
