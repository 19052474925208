import React from 'react';
// Import Contexts
// Import Hooks
// Import Components
import SelectorGrid from 'common/selects/SelectorGrid';
import {
	MdOutlineAddCircleOutline,
	MdOutlineFileCopy,
	MdOutlineRemoveCircleOutline
} from 'react-icons/md';
import { Modal } from 'react-responsive-modal';
// Import Tabs
import NQR from '../ExtraTabs/NQR';
import Precautions from '../ExtraTabs/Precautions';
import Reports from '../ExtraTabs/Reports';
import ProductServices from '../ExtraTabs/TabFourth/ProductServices';
import TechnicalAssign from '../ExtraTabs/TabOne/TechnicalAssign';
import Activities from '../ExtraTabs/TabTwo/Activities';
import Trazabilidad from '../ExtraTabs/Trazabilidad';
// Import Modals
import ExternalUserModal from '../Modals/ExternalUserModal';
import ClientModal from '../Modals/ModalClientSearch';
import ModalSearch from '../Modals/ModalSearch';
import Quotation from '../Modals/Quotation';
// Import libs
import { Formiz, FormizStep } from '@formiz/core';
import dateFormat from 'dateformat';
import { FormattedMessage } from 'react-intl';
// // Import services
import paths from 'services/paths';
// Import Styles
import { TimePicker } from '@material-ui/pickers';
import { ACTIVITIES } from 'constants/lang/services/services/activities';
import { COLLECTIONS } from 'constants/lang/services/services/collections';
import { REPORTS } from 'constants/lang/services/services/reports';
import { SERVICE_STATUS } from 'constants/serviceStatus';
import 'react-responsive-modal/styles.css';
import 'styles/services.css';
import InvoiceTable from '../ExtraTabs/invoiceTable';
import ModalBillingUser from '../Modals/ModalBillingUser';
import styles from '../Service.module.css';
import EditServiceViewModel from './EditService.viewModel';

const EditService = () => {
	const {
		allStateService,
		canDeleteAnsNQR,
		canDeleteNQR,
		canReopen,
		changeStatusNQR,
		clientSelected,
		cloneService,
		customSB,
		deleteBillingUser,
		eventData,
		externalUserSelected,
		formChildrens,
		formItem,
		formParent,
		getAllUsersServices,
		getActivitiesActive,
		getDataToUpdate,
		handlerTextDescription,
		hashCanAnulate,
		hashReopenService,
		idEstadoServicio,
		idService,
		isDeleteTechMain,
		isGenClient,
		loadingProgress,
		manualSearch,
		myForm,
		navigate,
		now,
		onOpenQuotation,
		onOpenSearch,
		onCloseClient,
		onCloseExternalUser,
		onCloseQuotation,
		onCloseSearch,
		onToggleBillingUser,
		openBillingUser,
		openClient,
		openExternalUser,
		openSearch,
		permittedActions,
		ppTech,
		searchAutomatic,
		selectedSearch,
		selectIds,
		setBillingUserSelected,
		setCheckoutValidations,
		setEstadoServicio,
		setFormItem,
		setIsDeleteTechMain,
		setPpTech,
		setSelectIds,
		setShowServiceData,
		showInvoice,
		showServiceData,
		time,
		userSelected,
		valueSetter,
		handleSubmit,
		formatterText,
		DisplayProgress,
		addressData,
		reOpenService,
		checkoutValidations,
		activityStart,
		handleDate,
		billingUserSelected,
		handleDownload,
		activityNoTechEnd,
		ticket,
		expedient,
		quotation
	} = EditServiceViewModel();
	return (
		<>
			<div className="centered-form">
				<form
					onSubmit={(e) => {
						e.preventDefault();
						handleSubmit();
					}}
					className="container-wrapForm"
					style={{ minHeight: '16rem' }}
				>
					<div className="new-container-wrapForm__tabs">
						<div className="new-tab-option is-active">
							<p
								onClick={(e) => {
									e.preventDefault();

									setShowServiceData(!showServiceData);
								}}
							>
								{formatterText(
									'header.title.panel.service',
									'Información del servicio - Número Expediente'
								)}{' '}
								{formItem.expediente}{' - '}
								{formatterText(
									'header.title.panel.service.ticket',
									'Número Ticket'
								)}{' '}{formItem.ticket}
							</p>
							<button
								className="p-show-services"
								onClick={(e) => {
									e.preventDefault();

									setShowServiceData(!showServiceData);
								}}
							>
								{showServiceData
									? formatterText('id.service.hide detail', 'Ocultar Detalle')
									: formatterText('id.service.view.detail', 'Ver detalle')}
							</button>
						</div>
					</div>
					<div className="container-wrapForm-content">
						{loadingProgress ? (
							<DisplayProgress />
						) : (
							showServiceData && (
								<>
									<section className="w100-container">
										<section
											style={{
												display: 'flex',
												width: '100%',
												justifyContent: 'flex-start',
												alignItems: 'center'
											}}
										>
											<p
												className="p-clone-action"
												style={{
													padding: '1rem'
												}}
												onClick={(e) => {
													e.preventDefault();
													cloneService();
												}}
											>
												{formatterText('p.title.clone.service', 'Clonar servicio')}
											</p>
											<MdOutlineFileCopy
												size={25}
												color="gray"
												cursor="pointer"
												onClick={() => cloneService()}
											/>
										</section>
										<section className="padding-all-1">
											{/* gen, user and direction */}
											<section className="grid-container-2c">
												<label className="d-flex">
													<section className="box-text-container">
														{isGenClient ? (
															<p className="text-inline">
																<FormattedMessage
																	id="p.generate.Client"
																	defaultMessage="Generado / Cliente"
																/>
															</p>
														) : (
															<p className="text-inline">Generado / Usuario</p>
														)}
													</section>
													<div className="wrap-input">
														<div className="input-label-icon">
															{isGenClient ? (
																<input
																	type="text"
																	name="idGeneradorCliente"
																	className="input-label-style"
																	value={clientSelected.nombre}
																	disabled
																/>
															) : (
																<input
																	type="text"
																	name="idGeneradorCliente"
																	className="input-label-style"
																	value={userSelected.nombre}
																	disabled
																/>
															)}
															<div className="btn-action-style">
																<MdOutlineRemoveCircleOutline size={25} color="gray" />
															</div>
														</div>
													</div>
												</label>

												<label className="d-flex">
													<section className="box-text-container">
														<p className="text-inline">
															{formatterText('p.generate.user', 'Usuario')}
														</p>
													</section>

													<div className="wrap-input">
														<div className="input-label-icon">
															<input
																type="text"
																name="idUsuario"
																className="input-label-style"
																value={externalUserSelected.nombre}
																required
																disabled
															/>
															<div className="btn-action-style">
																<MdOutlineRemoveCircleOutline size={25} color="gray" />
															</div>
														</div>
													</div>
												</label>

												<label className="d-flex">
													<section className="box-text-container">
														<p className="spacing-r1 p-styles margin-bottom-1">
															{formatterText('p.label.direction')}
														</p>
													</section>
													<input
														type="text"
														name="idDireccion"
														className="input-label-style"
														value={addressData}
														disabled
													/>
												</label>

												<label className="d-flex">
													<section className="box-text-container">
														<p className="spacing-r1 p-styles margin-bottom-1">
															{' '}
															{formatterText('p.status.service', 'Estado servicio')}
														</p>
													</section>
													<select
														name="idEstadoServicio"
														disabled
														className="input-label-style"
														defaultValue={idEstadoServicio}
														value={idEstadoServicio}
														onChange={(e) => {
															if (e.target.value !== '0') {
																setEstadoServicio(parseInt(e.target.value), idService);
															}
														}}
													>
														<option value="0">Estados de servicios</option>
														{allStateService.map((item, key) => (
															<option key={key} value={item.id}>
																{item.label}
															</option>
														))}
													</select>
													{hashReopenService[parseInt(idEstadoServicio)] && canReopen && (
														<p
															className="btn-primary"
															style={{
																minWidth: '5%',
																minHeight: '2rem',
																padding: '0.5rem',
																margin: '0.5rem',
																cursor: 'pointer',
																textAlign: 'center'
															}}
															onClick={reOpenService}
														>
															{formatterText('p.button.reopen.service', 'Reabrir servicio')}
														</p>
													)}
												</label>
												{/* make a button to reopen service */}
											</section>

											{/* exped num and type service */}
											<section className="grid-container-2c">
												<label className="d-flex custom-margin-0100-s">
													<p className="text-inline margin-bottom-1">NoExpediente</p>
													<section className="w100-container">
														<input
															className={
																checkoutValidations.equalsExp
																	? 'input-default input-default-disable'
																	: 'input-default'
															}
															type="text"
															name="expediente"
															value={formItem.expediente}
															onChange={(e) => {
																setFormItem({
																	...formItem,
																	[e.target.name]: e.target.value
																});
															}}
															placeholder="NoExpediente"
															disabled={checkoutValidations.equalsExp}
														/>
														<label
															className="label-position"
															style={{
																display: 'ruby-text-container'
															}}
														>
															<input
																type="checkbox"
																name="equalsExp"
																className=""
																onChange={(e) => {
																	setCheckoutValidations((prev) => {
																		if (e.target.checked) {
																			setFormItem({
																				...formItem,
																				expediente: ''
																			});
																		}

																		return {
																			...prev,
																			[e.target.name]: e.target.checked
																		};
																	});
																}}
																checked={checkoutValidations.equalsExp}
															/>
															<p
																className="text-checkout"
																style={{
																	width: 'fit-content',
																	display: 'block'
																}}
															>
																{formatterText('p.label.no.record', 'Usar mismo No. Expediente')}
															</p>
														</label>
													</section>
												</label>

												<label className="d-flex">
													<p className="text-inline margin-bottom-1">
														{formatterText('id.day.event.service.type', 'Tipo de servicio:')}
													</p>
													<section className="w100-container">
														<SelectorGrid
															disabled={
																hashCanAnulate[parseInt(idEstadoServicio)] && !activityStart
															}
															name="idTipoServicio"
															data={selectedSearch.typeServiceByTech}
															placeholder={formItem.idTipoServicio}
															dataValue={selectIds.idTipoServicio}
															setterFunction={setSelectIds}
															permission={permittedActions.cambiar_tipo_servicio}
															managePermission={true}
														/>
														<label className="label-position">
															{clientSelected.esVip === 1 && (
																<>
																	<input
																		type="checkbox"
																		name="specialService"
																		className=""
																		onChange={() => {
																			setCheckoutValidations((prev) => {
																				return {
																					...prev,
																					specialService: true
																				};
																			});
																		}}
																		checked={true}
																	/>
																	<p className="text-checkout">
																		{formatterText(
																			'id.day.check.service.isspecial',
																			'Es un servicio especial'
																		)}
																	</p>
																</>
															)}
														</label>
													</section>
												</label>
											</section>
											{/* description */}
											<section
												className=""
												style={{
													display: 'flex',
													flexDirection: 'row',
													flexWrap: 'wrap',
													fontFamily: 'inherit',
													justifyContent: 'space-between',
													alignItems: 'center',
													width: '100%'
												}}
											>
												<section className="container-description">
													<p
														className="p-styles"
														style={{
															textDecoration: 'none',
															fontSize: '1rem',
															fontWeight: 600,
															color: 'var(--dark-gray)'
														}}
													>
														{formatterText('table.title.description', 'Descripción')}
													</p>
													<textarea
														className="input-default-textarea"
														name="descripcion"
														value={formItem.descripcion}
														onChange={handlerTextDescription}
														placeholder={formatterText('table.title.description', 'Descripción')}
														maxLength="200"
													/>
												</section>
											</section>

											<section className="grid-container-2c">
												<label className="d-flex">
													<p className="text-inline">
														{' '}
														{formatterText('p.label.comunication.media', 'Medio de comunicación')}
													</p>
													<SelectorGrid
														name="idMedioComunicacion"
														data={selectedSearch.communicationMedia}
														placeholder={selectIds.idMedioComunicacion.label}
														dataValue={selectIds.idMedioComunicacion.label}
														setterFunction={setSelectIds}
													/>
												</label>

												<label className="d-flex">
													<p className="text-inline">
														{formatterText('p.label.comunication.channel', 'Canal de comunicación')}
													</p>
													<SelectorGrid
														name="idCanalComunicacion"
														data={selectedSearch.communicationChannel}
														placeholder={selectIds.idCanalComunicacion.label}
														dataValue={selectIds.idCanalComunicacion.label}
														setterFunction={setSelectIds}
													/>
												</label>

												<label className="d-flex">
													<p className="text-inline">
														{formatterText('p.label.date.appointment', 'Fecha de la cita')}
													</p>
													<input
														className="input-date"
														type="date"
														name="fechaCita"
														value={eventData.fechaCita}
														onChange={handleDate}
														min={dateFormat(
															new Date(now.getFullYear(), now.getMonth(), now.getDate() - 60),
															'yyyy-mm-dd'
														)}
													/>
												</label>

												<label className="d-flex">
													<p className="text-inline">
														{formatterText('p.label.hour.appointment', 'Hora de la cita')}
													</p>

													<TimePicker
														name="idHoraCita"
														ampm={false}
														placeholder={
															<FormattedMessage
																id="input.placeholder.select"
																defaultMessage="Selecciona una opción"
															/>
														}
														value={time}
														onChange={handleDate}
													/>
												</label>
											</section>
											<section className="grid-container-2c">
												<label className="d-flex">
													<section className="box-text-container">
														<p className="text-inline">
															{formatterText('p.label.billing.user', 'Usuario facturación')}
														</p>
													</section>

													<div className="wrap-input">
														<div className="input-label-icon">
															<input
																type="text"
																name="idUsuarioFactura"
																className="input-label-style"
																value={valueSetter(billingUserSelected.nombre)}
																required
																disabled
															/>
															<div className="btn-action-style">
																{billingUserSelected.id !== null ? (
																	<MdOutlineRemoveCircleOutline
																		size={25}
																		color="gray"
																		cursor="pointer"
																		onClick={(e) => {
																			new Promise((resolve) => {
																				e.preventDefault();
																				resolve();
																			}).then(() => {
																				deleteBillingUser();
																				setBillingUserSelected({
																					nombre: 'Seleccione usuario facturación(Optional)',
																					id: null
																				});
																			});
																		}}
																	/>
																) : (
																	<MdOutlineAddCircleOutline
																		size={25}
																		color="gray"
																		cursor="pointer"
																		onClick={(e) => {
																			new Promise((resolve) => {
																				e.preventDefault();
																				resolve();
																			}).then(() => {
																				onToggleBillingUser();
																			});
																		}}
																	/>
																)}
															</div>
														</div>
													</div>
												</label>
											</section>
										</section>
										{formParent.length !== 0 && (
											<section>
												<h3 className={styles['text-association']}>
													{formatterText('label.text.parent.service', 'Servicio padre')}
												</h3>
												<div style={{ marginLeft: '50px', marginRight: '50px' }}>
													<table
														style={{
															width: '100%',
															textAlign: 'center',
															border: '1px solid transparent !important'
															// borderCollapse: 'revert-layer'
														}}
													>
														<thead style={{ border: 'none' }}>
															<tr style={{ border: 'none' }}>
																<th style={{ border: 'none' }}>
																	{' '}
																	<label className="text-inline">
																		{formatterText('table.title.expedient', 'Expediente')}
																	</label>
																</th>
																<th style={{ border: 'none' }}>
																	<label className="text-inline">
																		{formatterText('text.ticket', 'Expediente')}
																	</label>
																</th>
															</tr>
														</thead>
														<tbody>
															{formParent.map((item) => (
																<tr key={item.idServicio} style={{ border: 'none' }}>
																	<td>{item.expediente}</td>
																	<td>{item.ticket}</td>
																	<td>
																		<button
																			style={{
																				borderRadius: '20px',
																				backgroundColor: '#0aa48a',
																				padding: '0px 1rem',
																				fontSize: 'bold',
																				color: '#fff',
																				border: 'none'
																			}}
																			onClick={(e) => {
																				e.preventDefault();
																				window.open(
																					`#/servicios/editar/${item.idServicio}`,
																					'_blank'
																				);
																			}}
																		>
																			{formatterText('view.details', 'Ver detalle')}
																		</button>
																	</td>
																</tr>
															))}
														</tbody>
													</table>
												</div>
											</section>
										)}
										{formChildrens.length !== 0 && (
											<section>
												<h3 className={styles['text-association']}>
													{formatterText('label.text.child.service', 'Servicios hijos')}
												</h3>
												<div style={{ marginLeft: '50px', marginRight: '50px' }}>
													<table
														style={{
															width: '100%',
															textAlign: 'center',
															border: '1px solid transparent !important'
															// borderCollapse: 'revert-layer'
														}}
													>
														<thead style={{ border: 'none' }}>
															<tr style={{ border: 'none' }}>
																<th style={{ border: 'none' }}>
																	{' '}
																	<label className="text-inline">
																		{formatterText('table.title.expedient', 'Expediente')}
																	</label>
																</th>
																<th style={{ border: 'none' }}>
																	<label className="text-inline">
																		{formatterText('text.ticket', 'Expediente')}
																	</label>
																</th>
															</tr>
														</thead>
														<tbody>
															{formChildrens.map((item) => (
																<tr key={item.idServicio} style={{ border: 'none' }}>
																	<td>{item.expediente}</td>
																	<td>{item.ticket}</td>
																	<td>
																		<button
																			style={{
																				borderRadius: '20px',
																				backgroundColor: '#0aa48a',
																				padding: '0px 1rem',
																				fontSize: 'bold',
																				color: '#fff',
																				border: 'none'
																			}}
																			onClick={(e) => {
																				e.preventDefault();
																				window.open(
																					`#/servicios/editar/${item.idServicio}`,
																					'_blank'
																				);
																			}}
																		>
																			{formatterText('view.details', 'Ver detalle')}
																		</button>
																	</td>
																</tr>
															))}
														</tbody>
													</table>
												</div>
											</section>
										)}
									</section>
									<input type="submit" id="submit-form" style={{ visibility: 'hidden' }} />
								</>
							)
						)}
						<div
							style={{
								//center the button
								display: 'flex',
								justifyContent: 'space-evenly',
								alignItems: 'center',
								marginBottom: '2rem'
							}}
						>
							{hashCanAnulate[parseInt(idEstadoServicio)] && activityStart && (
								<button
									style={{ padding: '0px', marginHorizontal: '2rem' }}
									className="btn-primary"
									onClick={(e) => {
										e.preventDefault();
										setEstadoServicio(7, idService);
									}}
								>
									<label className="btn-wrap-add">
										{formatterText('p.service.cancel.button', 'Anular servicio')}
									</label>
								</button>
							)}
							<button
								style={{ padding: '0px', marginHorizontal: '2rem' }}
								className="btn-primary"
								onClick={(e) => {
									e.preventDefault();
									handleDownload();
								}}
							>
								<label className="btn-wrap-add">
									{formatterText(
										'p.service.resume.download.button',
										'Descargar resumen del servicio'
									)}
								</label>
							</button>
							{idEstadoServicio === 9 && activityNoTechEnd && (
								<button
									style={{ padding: '0px' }}
									className="btn-primary"
									onClick={(e) => {
										e.preventDefault();
										setEstadoServicio(8, idService);
									}}
								>
									<label className="btn-wrap-add">
										{formatterText('p.service.end.button', 'Finalizar Servicio')}
									</label>
								</button>
							)}
						</div>
						<div className="demo-form__footer">
							<section className="form-responsive-container-buttons">
								<button
									style={{ padding: '0px' }}
									className="btn-primary"
									onClick={(e) => {
										e.preventDefault();
										handleSubmit();
									}}
								>
									<label className="btn-wrap-add" htmlFor="submit-form" tabIndex="0">
										{formatterText('btn.save.changes', 'Guardar cambios')}
									</label>
								</button>
								<button
									style={{ padding: '0px' }}
									className="btn-primary"
									onClick={(e) => {
										e.preventDefault();

										const STATE_SERVICE_BAN = {
											5: true,
											7: true,
											8: true,
											9: true
										};

										if (STATE_SERVICE_BAN[idEstadoServicio]) {
											customSB(
												'warning',
												'snackbar.warning.state.service.no.valid',
												'Solo se puede descargar cotizaciones en estados: En Coordinación, Asignado, En Desplazamiento, En Sitio, En Curso.'
											);
										} else {
											onOpenQuotation();
										}
									}}
								>
									<label className="btn-wrap-add">
										{formatterText('btn.download.quotation', 'Descargar cotización')}
									</label>
								</button>
								<button className="input-cancel" onClick={() => navigate(paths.serviceModule)}>
									{formatterText('btn.cancel', 'Cancelar')}
								</button>
							</section>
						</div>

						<Formiz onValidSubmit={handleSubmit} connect={myForm}>
							<form noValidate onSubmit={myForm.submit} className="container-options-tables">
								<div
									className="new-container-wrapForm__tabs"
									style={{
										overflow: 'auto'
									}}
								>
									{myForm.steps.map((step) => (
										<button
											key={step.name}
											className={`tab-options-tables ${
												step.name === myForm.currentStep.name ? 'is-active' : ''
											}`}
											type="button"
											onClick={() => myForm.goToStep(step.name)}
										>
											{formatterText(step.label)}
											{!step.isValid && step.isSubmitted && <small className="mr-2">⚠️</small>}
										</button>
									))}
								</div>

								<div className="container-table-content">
									<FormizStep
										style={{
											overflow: 'auto',
											marginTop: '1rem'
										}}
										name="step1"
										label="id.technicians.assigned"
									>
										<TechnicalAssign
											serviceState={parseInt(idEstadoServicio)}
											ppTech={ppTech}
											idService={idService}
											searchAutomatic={searchAutomatic}
											manualSearch={manualSearch}
											setPpTech={setPpTech}
											getDataToUpdate={getDataToUpdate}
											onOpenSearch={onOpenSearch}
											isDeleteTechMain={isDeleteTechMain}
										/>
									</FormizStep>
									{/*    // asdasd */}

									<FormizStep name="step2" label={ACTIVITIES.title}>
										<Activities
											getActivitiesActive={getActivitiesActive}
											statusActivity={parseInt(idEstadoServicio)}
											getDataToUpdate={getDataToUpdate}
											setIsDeleteTechMain={setIsDeleteTechMain}
											isDeleteTechMain={isDeleteTechMain}
											ppTech={ppTech}
										/>
									</FormizStep>

									<FormizStep name="step3" label={COLLECTIONS.title}>
										<Precautions />
									</FormizStep>

									<FormizStep
										isEnabled
										name="step4"
										label="id.step.products"
									>
										<ProductServices />
									</FormizStep>

									<FormizStep
										name="step5"
										label={REPORTS.title}
										style={{
											marginTop: '1rem'
										}}
									>
										<Reports />
									</FormizStep>
									<FormizStep name="step6" label="NQR">
										<NQR
											changeStatusNQR={changeStatusNQR}
											canDeleteNQR={canDeleteNQR}
											canDeleteAnsNQR={canDeleteAnsNQR}
										/>
									</FormizStep>
									<FormizStep name="step7" label="id.step.traceability">
										<Trazabilidad />
									</FormizStep>
									<FormizStep name="step8" label="label.text.billing.type">
										{showInvoice && <InvoiceTable ticket={ticket} expedient={expedient} />}
									</FormizStep>
									{/* This button is hidden, but the reference in the id helps to execute the form (you can check it below in the class called "text")*/}
									<input type="submit" id="submit-form" style={{ visibility: 'hidden' }} />
								</div>
							</form>
						</Formiz>
					</div>
				</form>
			</div>
			{/* Client system modal */}
			<Modal
				Modal
				open={openClient}
				onClose={onCloseClient}
				center
				classNames={{
					overlay: 'customOverlay',
					modal: 'customModal'
				}}
			>
				<ClientModal onClose={onCloseClient} exeFun={getAllUsersServices} />
			</Modal>

			{/* Billing user modal */}
			<Modal
				open={openBillingUser}
				onClose={onToggleBillingUser}
				center
				classNames={{
					overlay: 'customOverlay',
					modal: 'customModal'
				}}
			>
				<ModalBillingUser onClose={onToggleBillingUser} exeFun={getAllUsersServices} />
			</Modal>
			{/* External user modal */}
			<Modal
				Modal
				open={openExternalUser}
				onClose={onCloseExternalUser}
				center
				classNames={{
					overlay: 'customOverlay',
					modal: 'customModal'
				}}
			>
				<ExternalUserModal onClose={onCloseExternalUser} exeFun={getAllUsersServices} />
			</Modal>
			{/* Search data modal */}
			<Modal
				Modal
				open={openSearch}
				onClose={onCloseSearch}
				center
				classNames={{
					overlay: 'customOverlay',
					modal: 'customModal'
				}}
			>
				<ModalSearch
					onClose={onCloseSearch}
					exeFun={getAllUsersServices}
					serviceId={idService}
					permissions={{
						automatic: searchAutomatic,
						manual: manualSearch
					}}
				/>
			</Modal>
			{/* Cotización data modal */}
			<Modal
				Modal
				open={quotation}
				onClose={onCloseQuotation}
				center
				classNames={{
					overlay: 'customOverlay',
					modal: 'customModal'
				}}
			>
				<Quotation onClose={onCloseQuotation} />
			</Modal>
		</>
	);
};

export default EditService;
