import { useForm } from '@formiz/core';
import HandlerTextDescription from 'common/validators/HandlerTextDescription';
import { MODULES_NAME } from 'common/validators/PermissionsConstants';
import CustomAlert from 'components/CustomAlert';
import { ACTIVITYSTATUS } from 'constants/activityStatus';
import { AppContext } from 'context/AppContext';
import { usePermissionContext } from 'context/PermissionContext';
import { TableMinimalContext } from 'context/TableMinimalContext';
import dateFormat from 'dateformat';
import useAxios from 'hooks/useAxios';
import useLangv2 from 'hooks/useLangv2';
import usePermission from 'hooks/usePermission';
import useProgress from 'hooks/useProgress';
import { sortBy } from 'lodash';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import 'react-responsive-modal/styles.css';
import { useNavigate, useParams } from 'react-router-dom';
import endpoints from 'services/api';
import paths from 'services/paths';
import 'styles/services.css';
import Swal from 'sweetalert2';
import hours from '../hours';
import { valueSetter } from '../utils/string';

export default function EditServiceViewModel() {
	const hashCanAnulate = {
		1: true,
		2: true,
		3: true,
		4: true,
		5: true,
		6: true,
		7: false
	};

	const hashReopenService = {
		1: false,
		2: false,
		3: false,
		4: false,
		5: false,
		6: false,
		7: false,
		8: true,
		9: true
	};

	// use Hook of language v2
	const {
		formatterText,
		errorProcess,
		showSB,
		showPermissionDenied,
		customSB,
		handleRequestError,
		successRemoveItem,
		showDownloadFile
	} = useLangv2();
	// Helps to loading data table
	const { loadingProgress, setLoadingProgress, DisplayProgress } = useProgress();
	// Address Data
	const [addressData, setAddressData] = useState('Loading...');
	// Formiz object
	const myForm = useForm();
	// get id from the url
	const { id } = useParams();
	// getData from the client or exteralUser select
	const {
		clientSelected,
		listActivities,
		externalUserSelected,
		userSelected,
		billingUserSelected,
		setBillingUserSelected,
		setExternalUserSelected,
		setClientSelected,
		setUserSelected,
		setServiceCloneData,
		idEstadoServicio,
		setIdEstadoServicio,
		idBillingService,
		setIdBillingService
	} = useContext(AppContext);
	const navigate = useNavigate();
	// Manage axios requests
	const { COOKIE_USER, RequestUseCase } = useAxios();

	// auxService data
	const [auxService, setAuxService] = useState([]);

	// permissions
	const [searchAutomatic, setSearchAutomatic] = useState(false);
	const [manualSearch, setManualSearch] = useState(false);
	const { permissions, permissionPerModule } = usePermission();

	// Value that select use
	const [selectedSearch, setSearchSelected] = useState([
		{
			// news
			clients: [],
			address: [],
			users: [],
			typeService: [],
			communicationMedia: [],
			timeMeeting: [],
			communicationChannel: [],
			typeServiceByTech: []
		}
	]);

	const [ppTech, setPpTech] = useState({
		idtech: -1,
		label: ''
	});

	// Helps to save the ids and labels of the selects data
	const [selectIds, setSelectIds] = useState({
		idGeneradorCliente: {
			value: 0,
			label: ''
		},
		idUsuario: {
			value: 0,
			label: ''
		},
		idDireccion: {
			value: 0,
			label: ''
		},
		idTipoServicio: {
			value: 0,
			label: ''
		},
		idMedioComunicacion: {
			value: 0,
			label: ''
		},
		idHoraCita: {
			value: 0,
			label: ''
		},
		idCanalComunicacion: {
			value: 0,
			label: ''
		}
	});

	// Stare of date picker
	const [time, setTime] = useState(new Date());

	useContext(TableMinimalContext);

	const [isDeleteTechMain, setIsDeleteTechMain] = useState(false);

	// checkouts states
	const [checkoutValidations, setCheckoutValidations] = useState({
		equalsExp: false,
		specialService: false
	});
	// permissions access
	const { access, setAccess, permittedActions } = usePermissionContext();
	const [NQRAccess, setNQRAccess] = useState([]);
	// permissions
	const [changeState, setChangeState] = useState(false);
	const [activityStart, setActivityStart] = useState(false);
	const [changeStatusNQR, setChangeStatusNQR] = useState(false);
	const [canDeleteNQR, setCanDeleteNQR] = useState(false);
	const [canDeleteAnsNQR, setCanDeleteAnsNQR] = useState(false);
	const [activityNoTechEnd, setActivityNoTechEnd] = useState(false);
	const [canReopen, setCanReopen] = useState(false);
	const [openBillingUser, setOpenBillingUser] = useState(false);

	// Modal config
	const [openClient, setOpenClient] = useState(false);
	const [openExternalUser, setOpenExternalUser] = useState(false);
	const [quotation, setQuotation] = useState(false);
	const [openSearch, setOpenSearch] = useState(false);

	const onToggleBillingUser = () => setOpenBillingUser(!openBillingUser);

	const [isGenClient, setIsGenClient] = useState(true);

	const [eventData, setEventData] = useState({
		fechaCita: '',
		idHoraCita: ''
	});

	const [originalData, setOriginalData] = useState({
		idTipoServicio: {
			value: '',
			id: -1
		}
	});

	const onCloseClient = () => setOpenClient(false);
	const onCloseExternalUser = () => setOpenExternalUser(false);
	const onOpenSearch = () => setOpenSearch(true);
	const onCloseSearch = () => setOpenSearch(false);
	const onOpenQuotation = () => setQuotation(true);
	const onCloseQuotation = () => setQuotation(false);
	const [allStateService, setAllStateService] = useState([]);
	const [expedient, setExpedient] = useState('');
	const [ticket, setTicket] = useState('');
	const [showInvoice, setShowInvoice] = useState(false);
	const now = new Date();

	const getActivities = () => {
		RequestUseCase.get(endpoints.services.getAllActivitiesStateByIdService(id))
			.then((data) => {
				const activitiesOnCurse = data.every(
					(activity) => activity.idEstadoActividad.idEstadoActividad == ACTIVITYSTATUS.INICIAR
				);
				if (activitiesOnCurse) {
					setIsDeleteTechMain(true);
				}
			})
			.catch(() => {
				//handleClick();
			});
	};

	useEffect(() => {
		localStorage.setItem('idEstadoServicio', idEstadoServicio.toString());
	}, [idEstadoServicio]);

	const getAllStatesService = () => {
		RequestUseCase.get(endpoints.services.getAllStates).then((data) => {
			const resp = data.map((item) => ({
				id: item.idEstadoServicio,
				label: item.nombre
			}));
			const sorted = sortBy(resp, 'id');
			setAllStateService(sorted);
		});
	};

	const permissionsAccess = () => {
		const filterNQR = permissionPerModule(MODULES_NAME.service.nqr);
		const filterAccess = permissionPerModule(MODULES_NAME.service.principal);
		setNQRAccess(filterNQR);
		setAccess([...filterAccess]);
	};

	const validationData = () => {
		access.forEach((element) => {
			switch (element.nombrePermiso) {
				case 'CAMBIAR_TIPO_SERVICIO':
					break;
				case 'CAMBIAR_ESTADO':
					setChangeState(true);
					break;
				case 'REABRIR':
					setCanReopen(true);
					break;
				case 'BUSQUEDA_MANUAL':
					setManualSearch(true);
					break;
				case 'BUSQUEDA_AUTOMATICA':
					setSearchAutomatic(true);
					break;
				default:
					break;
			}
		});
		validationNQR();
	};

	const validationNQR = () => {
		NQRAccess.forEach((element) => {
			switch (element.nombrePermiso) {
				case 'CAMBIAR_ESTADO':
					setChangeStatusNQR(true);
					break;
				case 'ELIMINAR':
					setCanDeleteNQR(true);
					break;
				case 'ELIMINAR_RESPUESTA':
					setCanDeleteAnsNQR(true);
					break;
				default:
					break;
			}
		});
	};

	const getBillingData = async () => {
		try {
			const res = await RequestUseCase.get(endpoints.services.getBillingUserByService(id));

			res.forEach((item) => {
				if (item) {
					const billingClient = item.idCliente;
					setIdBillingService(item.idServicioFacturacion);
					const name = billingClient
						? `${billingClient.nombreRazonSocial}-${billingClient.identificacion}-${billingClient.correo}`
						: formatterText(
								'placeholder.user.was.not.selected',
								'No fue seleccionado ningún usuario'
						  );
					setBillingUserSelected({
						nombre: name,
						id: billingClient?.idCliente || null
					});
				}
			});
		} catch (err) {
			console.error(err);
		}
	};

	const dataFact = (dataJson) => {
		setExpedient(dataJson.expediente);
		setTicket(dataJson.ticket);
	};

	// get data from the localStorage
	const getDataToUpdate = () => {
		RequestUseCase.get(endpoints.services.getServiceById(id))
			.then((res) => {
				setAuxService(res);

				const dataJson = res.servicio;
				setIdService(id);
				setIdEstadoServicio(dataJson.idEstadoServicio.idEstadoServicio);

				let dataClient = '';
				let dataUser = '';
				let dataSystemUser = '';
				let dataUserId = null;
				let dataClientId = null;
				let dataGenUserId = null;

				let controlGenerator = true;

				if (dataJson.idGeneradorCliente !== null) {
					dataClient = `${dataJson.idGeneradorCliente.nombreRazonSocial} - ${dataJson.idGeneradorCliente.identificacion}`;
					dataClientId = dataJson.idGeneradorCliente.idCliente;
					if (dataJson.idUsuario) {
						dataUser = `${dataJson.idUsuario.primerNombre} ${dataJson.idUsuario.primerApellido} - ${dataJson.idUsuario.telefono} `;
						dataUserId = dataJson.idUsuario.idUsuarioServicio;
					} else {
						dataUser = formatterText(
							'placeholder.user.was.not.selected',
							'No fue seleccionado ningún usuario'
						);
					}
				} else {
					// this the data of the system user
					dataSystemUser = `${dataJson.idGeneradorUsuario.primerNombre} ${dataJson.idGeneradorUsuario.primerApellido} - ${dataJson.idGeneradorUsuario.telefono}`;
					dataGenUserId = dataJson.idGeneradorUsuario.idUsuarioServicio;
					if (dataJson.idUsuario) {
						dataUser = `${dataJson.idUsuario.primerNombre} ${dataJson.idUsuario.primerApellido} - ${dataJson.idUsuario.telefono} `;
						dataUserId = dataJson.idUsuario.idUsuarioServicio;
					} else {
						dataUser = formatterText(
							'placeholder.user.was.not.selected',
							'No fue seleccionado ningún usuario'
						);
					}
					controlGenerator = false;
				}

				// set Address
				setAddressData(
					`${dataJson.idDireccion.direccion} - ${dataJson.idDireccion.datosAdicionales}`
				);

				dataFact(dataJson);

				// set form
				setFormItem({
					...dataJson,
					descripcion: dataJson.descripcion,
					expediente: dataJson.expediente,
					idGeneradorCliente: dataClient,
					idDireccion: dataJson.idDireccion.direccion,
					idTipoServicio: `${dataJson.idTipoServicio.nombre} - ${dataJson.idTipoServicio.idCategoriaServicio.nombre}`,
					idHoraCita: dataJson.idHoraCita.hora,
					idUsuario: dataUser
				});
				setTime(moment(dataJson.idHoraCita.hora, 'HH:mm').toDate());
				const padre = res.servicioPadre === null ? [] : [res.servicioPadre];
				const hijos = res.servicioHijo === null ? [] : res.servicioHijo;
				// set clone info
				setFormParent(padre);
				setFormChildrens(hijos);

				// If is special service
				const specialService = dataJson.especial === 1;

				if (controlGenerator) {
					setIsGenClient(controlGenerator);
					setClientSelected({
						nombre: dataClient,
						id: dataClientId,
						esVip: specialService ? 1 : 2
					});
				} else {
					setIsGenClient(controlGenerator);
					setUserSelected({
						nombre: dataSystemUser,
						id: dataGenUserId,
						esVip: specialService ? 1 : 2
					});
				}

				// set external user
				setExternalUserSelected({
					nombre: dataUser,
					id: dataUserId
				});

				// set meet date
				setEventData({
					fechaCita: dataJson.fechaCita.split('T')[0]
				});

				// Checks sect
				setCheckoutValidations((prev) => {
					return {
						...prev,
						specialService: specialService
					};
				});

				const equalsExp = dataJson.expediente === dataJson.ticket;
				setCheckoutValidations((prev) => {
					return {
						...prev,
						equalsExp: equalsExp
					};
				});

				setOriginalData({
					...originalData,
					idTipoServicio: {
						value: dataJson.idTipoServicio.idTipoServicio,
						id: dataJson.idTipoServicio.idTipoServicio
					}
				});

				setSelectIds({
					...selectIds,
					idDireccion: dataJson.idDireccion.idDireccion,
					idTipoServicio: {
						// value: dataJson.idTipoServicio.idTipoServicio,
						// label: dataJson.idTipoServicio.idTipoServicio,
						value: `${dataJson.idTipoServicio.idTipoServicio}-${dataJson.idTipoServicio.idCategoriaServicio.idCategoriaServicio}`,
						label: `${dataJson.idTipoServicio.nombre} - ${dataJson.idTipoServicio.idCategoriaServicio.nombre}`
					},
					idHoraCita: {
						value: dataJson.idHoraCita.idHora,
						id: dataJson.idHoraCita.idHora
					},
					idMedioComunicacion: {
						label: `${dataJson.idMedioComunicacion.nombre} - ${dataJson.idMedioComunicacion.codigo}`,
						value: dataJson.idMedioComunicacion.idMedioComunicacion
					},
					idCanalComunicacion: {
						label: `${dataJson.idCanalComunicacion.nombre} - ${dataJson.idCanalComunicacion.codigo}`,
						value: dataJson.idCanalComunicacion.idCanalComunicacion
					}
				});

				setLoadingProgress(false);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	// validate if the activities started
	const getActivitiesActive = () => {
		RequestUseCase.get(endpoints.services.getActivitiesActiveByService(id)).then((data) => {
			setActivityStart(data);
		});
	};

	const activitiesNoTechsEndeds = () => {
		RequestUseCase.get(endpoints.services.getActivitiesNoTechsByService(id)).then((data) => {
			setActivityNoTechEnd(data);
		});
	};

	const searchHour = (hour) => {
		// search in hours array the idHora that matches with the hour
		const idHora = hours.find((item) => item.hora === hour);
		return idHora.idHora;
	};

	// This function is executed when the create button is clicked
	const handleSubmit = () => {
		const dateService = new Date(auxService.servicio.fechaCita).toISOString().split('T')[0];
		let especial = 0;
		if (clientSelected.esVip === 1) {
			especial = clientSelected.esVip === 1 ? 1 : 0;
		} else if (userSelected.esVip === 1) {
			especial = userSelected.esVip === 1 ? 1 : 0;
		}
		const { idTipoServicio: serviceType = {} } = selectIds;

		const serviceTypeId = Number(serviceType.value.split('-')[0] || '0');

		let data = {
			idServicio: parseInt(id),
			idDireccion: {
				idDireccion: selectIds.idDireccion
			},
			idTipoServicio: {
				idTipoServicio: serviceTypeId
			},
			descripcion: formItem.descripcion ? formItem.descripcion : '',
			idMedioComunicacion: {
				idMedioComunicacion: selectIds.idMedioComunicacion.value
			},
			idCanalComunicacion: {
				idCanalComunicacion: selectIds.idCanalComunicacion.value
			},
			fechaCita: eventData.fechaCita ? eventData.fechaCita : formItem.expediente,
			idHoraCita: {
				idHora: eventData.idHoraCita ? eventData.idHoraCita : selectIds.idHoraCita.value
			},
			fechaCitaFin: formItem.fechaCitaFin,
			idHoraCitaFin: {
				idHora: formItem.idHoraCitaFin.idHora
			},
			expediente:
				checkoutValidations.equalsExp === null
					? formItem.expediente
					: checkoutValidations.equalsExp,
			especial,
			idEstadoServicio: {
				idEstadoServicio: idEstadoServicio
			},
			usuarioCreacion: formItem.usuarioCreacion,
			usuarioModificacion: COOKIE_USER,
			idServicioPadre: formItem.idServicioPadre,
			estado: formItem.estado,
			fechaCreacion: formItem.fechaCreacion,
			fechaModificacion: null,
			ticket: formItem.ticket
		};

		// OPTION 1: GUSER where idGeneradorCliente are null
		if (userSelected.id !== null) {
			// OPTION 2:HAVE USER SYSTEM Too
			if (externalUserSelected.id !== null) {
				// Select genUser and Externarl User
				data = {
					...data,
					idGeneradorCliente: null,
					idGeneradorUsuario: {
						idUsuarioServicio: parseInt(userSelected.id)
					},
					idUsuario: {
						idUsuarioServicio: parseInt(externalUserSelected.id)
					}
				};
			} else {
				// Only select gen system user Option 1 only
				data = {
					...data,
					idGeneradorCliente: null,
					idGeneradorUsuario: {
						idUsuarioServicio: parseInt(userSelected.id)
					},
					idUsuario: null
				};
			}
		}
		// OPTION 3: GCLIENT + GUSER where idGeneradorUsuario is null
		else if (clientSelected.id !== null) {
			// OPTION 4:HAVE USER SYSTEM Too
			if (externalUserSelected.id !== null) {
				// Select geClient and Externarl User
				data = {
					...data,
					idGeneradorCliente: {
						idCliente: parseInt(clientSelected.id)
					},
					idGeneradorUsuario: null,
					idUsuario: {
						idUsuarioServicio: parseInt(externalUserSelected.id)
					}
				};
			} else {
				// Option 3: Only GenClient
				data = {
					...data,
					idGeneradorCliente: {
						idCliente: clientSelected.id
					},
					idGeneradorUsuario: null,
					idUsuario: null
				};
			}
		} else {
			alert('Error to update service: notify admin');
		}
		//Only change type of service serviceTypeId
		if (idEstadoServicio != 1) {
			if (
				originalData.idTipoServicio.id !== serviceTypeId &&
				(selectIds.idCanalComunicacion.value ===
					auxService.servicio.idCanalComunicacion.idCanalComunicacion ||
					selectIds.idMedioComunicacion.value ===
						auxService.servicio.idMedioComunicacion.idMedioComunicacion ||
					dateService === eventData?.fechaCita ||
					selectIds.idHoraCita.value === auxService.servicio.idHoraCita.idHora)
			) {
				/* if (!activityStart) {
					customSB(
						'warning',
						'snackbar.warning.update.state.service',
						'No se puede actualizar el estado del servicio, debido a que el estado "En Curso" tiene actividades iniciadas.'
					);
				} else {
					console.log('WAY A');
				} */
				generateDateEnd(data);
			}

			if (
				originalData.idTipoServicio.id !== serviceTypeId &&
				selectIds.idCanalComunicacion.value !==
					auxService.servicio.idCanalComunicacion.idCanalComunicacion &&
				selectIds.idMedioComunicacion.value !==
					auxService.servicio.idMedioComunicacion.idMedioComunicacion &&
				dateService !== eventData?.fechaCita &&
				selectIds.idHoraCita.value !== auxService.servicio.idHoraCita.idHora
			) {
				if (!activityStart) {
					customSB(
						'warning',
						'snackbar.warning.update.state.service',
						'No se puede actualizar el estado del servicio, debido a que el estado "En Curso" tiene actividades iniciadas.'
					);
				} else {
					console.log('WAY B');
					generateDateEnd(data);
				}
			}

			if (
				dateService !== eventData?.fechaCita &&
				originalData.idTipoServicio.id === serviceTypeId &&
				(selectIds.idHoraCita.value !== auxService.servicio.idHoraCita.idHora ||
					selectIds.idCanalComunicacion.value !==
						auxService.servicio.idCanalComunicacion.idCanalComunicacion ||
					selectIds.idMedioComunicacion.value !==
						auxService.servicio.idMedioComunicacion.idMedioComunicacion)
			) {
				if (!activityStart) {
					customSB(
						'warning',
						'snackbar.warning.update.state.service',
						'No se puede actualizar el estado del servicio, debido a que el estado "En Curso" tiene actividades iniciadas.'
					);
				} else {
					console.log('WAY C');
					generateDateEndByCalendar(data);
				}
			}

			if (
				selectIds.idHoraCita.value !== auxService.servicio.idHoraCita.idHora &&
				originalData.idTipoServicio.id === serviceTypeId &&
				(dateService !== eventData?.fechaCita ||
					selectIds.idCanalComunicacion.value !==
						auxService.servicio.idCanalComunicacion.idCanalComunicacion ||
					selectIds.idMedioComunicacion.value !==
						auxService.servicio.idMedioComunicacion.idMedioComunicacion)
			) {
				if (!activityStart) {
					customSB(
						'warning',
						'snackbar.warning.update.state.service',
						'No se puede actualizar el estado del servicio, debido a que el estado "En Curso" tiene actividades iniciadas.'
					);
				} else {
					console.log('WAY D');
					generateDateEndByCalendar(data);
				}
			}

			if (
				(selectIds.idCanalComunicacion.value !==
					auxService.servicio.idCanalComunicacion.idCanalComunicacion ||
					selectIds.idMedioComunicacion.value !==
						auxService.servicio.idMedioComunicacion.idMedioComunicacion) &&
				dateService === eventData?.fechaCita &&
				selectIds.idHoraCita.value === auxService.servicio.idHoraCita.idHora &&
				originalData.idTipoServicio.id === serviceTypeId
			) {
				console.log('WAY E');
				putItem(data);
			}

			if (
				dateService !== eventData?.fechaCita &&
				originalData.idTipoServicio.id === serviceTypeId &&
				selectIds.idCanalComunicacion.value ===
					auxService.servicio.idCanalComunicacion.idCanalComunicacion &&
				selectIds.idMedioComunicacion.value ===
					auxService.servicio.idMedioComunicacion.idMedioComunicacion &&
				selectIds.idHoraCita.value === auxService.servicio.idHoraCita.idHora
			) {
				if (!activityStart) {
					customSB(
						'warning',
						'snackbar.warning.update.state.service',
						'No se puede actualizar el estado del servicio, debido a que el estado "En Curso" tiene actividades iniciadas.'
					);
				} else {
					console.log('WAY F');
					generateDateEndByCalendar(data);
				}
			}

			if (
				eventData?.idHoraCita &&
				selectIds.idHoraCita.value !== eventData?.idHoraCita &&
				originalData.idTipoServicio.id === serviceTypeId &&
				selectIds.idCanalComunicacion.value ===
					auxService.servicio.idCanalComunicacion.idCanalComunicacion &&
				selectIds.idMedioComunicacion.value ===
					auxService.servicio.idMedioComunicacion.idMedioComunicacion &&
				dateService === eventData?.fechaCita
			) {
				if (!activityStart) {
					customSB(
						'warning',
						'snackbar.warning.update.state.service',
						'No se puede actualizar el estado del servicio, debido a que el estado "En Curso" tiene actividades iniciadas.'
					);
				} else {
					console.log('WAY G');
					generateDateEndByCalendar(data);
				}
			}
			if (selectIds.idHoraCita.value !== auxService.servicio.idHoraCita.idHora) {
				generateDateEndByCalendar(data);
			}
		}

		if (idEstadoServicio === 1) {
			if (
				(selectIds.idCanalComunicacion.id !==
					auxService.servicio.idCanalComunicacion.idCanalComunicacion ||
					originalData.idTipoServicio.id !== serviceTypeId ||
					selectIds.idMedioComunicacion.value !==
						auxService.servicio.idMedioComunicacion.idMedioComunicacion) &&
				dateService === eventData?.fechaCita &&
				selectIds.idHoraCita.value === auxService.servicio.idHoraCita.idHora
			) {
				console.log('WAY I');
				putItem(data);
			}

			if (
				dateService !== eventData?.fechaCita ||
				selectIds.idHoraCita.value !== auxService.servicio.idHoraCita.idHora
			) {
				console.log('WAY J');
				putItem(data);
			}
		}
	};

	const generateDateEnd = (data) => {
		customSB(
			'warning',
			'snackbar.warning.validate.risk.exist',
			'Calculando riesgos de incumplimiento asociados, por favor espere...'
		);
		RequestUseCase.post(endpoints.services.generateEndDate, data)
			.then((response) => {
				const { idTipoServicio: serviceType = {} } = selectIds;
				const [serviceTypeId = '', serviceTypeCategory = ''] = (serviceType?.value || '').split(
					'-'
				);
				const ADDRESS = auxService.servicio.idDireccion.idDireccion;
				const SECTOR = auxService.servicio.idDireccion.idSector.idSector;
				const MUNICIPALITY = auxService.servicio.idDireccion.idSector.idMunicipio.idMunicipio;
				const TYPE_SERVICE = parseInt(serviceTypeId);
				const CATEGORY_SERVICE = parseInt(serviceTypeCategory);

				const DATA_ORIGINAL = data;

				const DATA_VALIDATED = {
					...data,
					idDireccion: {
						idDireccion: ADDRESS,
						idSector: {
							idSector: SECTOR,
							idMunicipio: {
								idMunicipio: MUNICIPALITY
							}
						}
					},
					idTipoServicio: {
						idTipoServicio: TYPE_SERVICE,
						idCategoriaServicio: {
							idCategoriaServicio: CATEGORY_SERVICE
						}
					},
					idHoraCita: {
						// idHora: formItem.idHoraCitaFin.idHora,
						// valor: dateformat(formItem.idHoraCitaFin.idHora, 'HH:MM').split(':').map(Number).reduce((horas, minutos) => horas + minutos / 60, 0),
						idHora: response.idHoraCita.idHora,
						valor: response.idHoraCita.valor
					},
					fechaCitaFin: response.fechaCitaFin.split('T')[0],
					idHoraCitaFin: {
						idHora: response.idHoraCitaFin.idHora,
						valor: response.idHoraCitaFin.valor
					}
				};
				validateServiceRiskEvent(DATA_VALIDATED, DATA_ORIGINAL);
			})
			.catch(() => {
				errorProcess();
			});
	};

	// Calculate service's risks
	const validateServiceRiskEvent = (DATA, DATA_ORIGINAL) => {
		RequestUseCase.post(endpoints.services.calculateServiceRisk, DATA)
			.then((response) => {
				if (response) {
					if (response?.length === 0) {
						return putItemWhitTech(DATA_ORIGINAL);
					}
					const risk = response[0];
					const START_AVAILABILITY_TIME =
						risk.tiempoDisponibilidadInicio === -1
							? formatterText('info.swal.previous.services', 'No hay servicio previo')
							: risk.tiempoDisponibilidadInicio.toFixed(2) +
							  ' ' +
							  formatterText('service.services.editService.activities.minutes');
					const END_AVAILABILITY_TIME =
						risk.tiempoDisponibilidadFin === -1
							? formatterText('info.swal.post.services', 'No hay servicio posterior')
							: risk.tiempoDisponibilidadFin.toFixed(2) +
							  ' ' +
							  formatterText('service.services.editService.activities.minutes');
					const low = formatterText('info.swal.low.risk', 'BAJO');
					const medium = formatterText('info.swal.medium.risk', 'MEDIO');
					const hight = formatterText('info.swal.hight.risk', 'ALTO');
					const PREVIOUS_BREACH_RISK =
						risk.riesgoIncumplimientoAnterior === 1
							? `<span style="color: #0aa48a; font-weight: bold;">${low}</span>`
							: risk.riesgoIncumplimientoAnterior === 2
							? `<span style="color: #f4bf4a; font-weight: bold;">${medium}</span>`
							: `<span style="color: #e21e26; font-weight: bold;">${hight}</span>`;

					const FOLLOWING_BREACH_RISK =
						risk.riesgoIncumplimientoSiguiente === 1
							? `<span style="color: #0aa48a; font-weight: bold;">${low}</span>`
							: risk.riesgoIncumplimientoSiguiente === 2
							? `<span style="color: #f4bf4a; font-weight: bold;">${medium}</span>`
							: `<span style="color: #e21e26; font-weight: bold;">${hight}</span>`;

					const alertText = `
          <ul style="padding: 0 3rem; text-align: start; font-size: 0.9rem">
            <li>${formatterText(
							'info.swal.start.availability.time',
							'Tiempo disponible al inicio'
						)}: ${START_AVAILABILITY_TIME}</li>
            <li>${formatterText(
							'info.swal.end.availability.time',
							'Tiempo disponible al final'
						)}: ${END_AVAILABILITY_TIME}</li>
            <li>${formatterText(
							'info.swal.risk.prior.default',
							'Riesgo de incumplimiento previo'
						)}: ${PREVIOUS_BREACH_RISK}</li>
            <li>${formatterText(
							'info.swal.risk.next.default',
							'Riesgo de incumplimiento siguiente'
						)}: ${FOLLOWING_BREACH_RISK}</li>
          </ul>
          `;
					const textLowRisk = `      
            <p style="padding: 0 3rem; text-align: justify-content; font-size: 0.9rem"> 
            ${formatterText(
							'info.swal.title.no.previous.service',
							'No hay servicios previos y/o posteriores que puedan verse afectados con este cambio.'
						)}</p><br/>
            <ul style="padding: 0 3rem; text-align: start; font-size: 0.9rem">
              <li>${formatterText(
								'info.swal.start.availability.time',
								'Tiempo disponible al inicio'
							)}: ${START_AVAILABILITY_TIME}</li>
              <li>${formatterText(
								'info.swal.end.availability.time',
								'Tiempo disponible al final'
							)}: ${END_AVAILABILITY_TIME}</li>
              <li>${formatterText(
								'info.swal.risk.prior.default',
								'Riesgo de incumplimiento servicio previo'
							)}: ${PREVIOUS_BREACH_RISK}</li>
              <li>${formatterText(
								'info.swal.risk.next.default',
								'Riesgo de incumplimiento servicio siguiente'
							)}: ${FOLLOWING_BREACH_RISK}</li>
            </ul>
          `;
					const titleTextDnger = `${formatterText(
						'info.swal.title.risk.hight',
						'Riesgo Alto'
					)} <br/> <p style="font-size: 1rem"> ${formatterText(
						'info.swal.title.previous.service',

						'Antes de continuar, verifica riesgos asociados al cumplimiento de tus servicios previos y/o posteriores'
					)}</p>`;
					const titleTextWarn = `${formatterText(
						'info.swal.title.risk.medium.hight',
						'Riesgo Medio - Alto'
					)} <br/> <p style="font-size: 1rem"> ${formatterText(
						'info.swal.title.previous.service',
						'Antes de continuar, verifica riesgos asociados al cumplimiento de tus servicios previos y/o posteriores'
					)}</p>`;
					const titlelOWrISK = `${formatterText('info.swal.title.risk.low', 'Riesgo Bajo')} <br/>`;

					Swal.fire({
						icon:
							risk.riesgoIncumplimientoAnterior > 1 || risk.riesgoIncumplimientoSiguiente > 1
								? 'warning'
								: 'success',
						title:
							risk.riesgoIncumplimientoAnterior === 3 || risk.riesgoIncumplimientoSiguiente === 3
								? titleTextDnger
								: risk.riesgoIncumplimientoAnterior === 2 ||
								  risk.riesgoIncumplimientoSiguiente === 2
								? titleTextWarn
								: titlelOWrISK,
						html:
							risk.riesgoIncumplimientoAnterior > 1 || risk.riesgoIncumplimientoSiguiente > 1
								? alertText
								: textLowRisk,
						confirmButtonColor: '#09a288',
						confirmButtonText: formatterText('alert.button.continue', 'Continuar'),
						showCancelButton: true,
						allowOutsideClick: true,
						cancelButtonText: formatterText('alert.button.cancel.general', 'Cancelar')
					}).then((result) => {
						if (result.isConfirmed) {
							putItemWhitTech(DATA_ORIGINAL);
						}
					});
				} else {
					customSB(
						'error',
						'snackbar.error.validate.tech.exist',
						'No se encontró un técnico disponible para este servicio, seleccione otro horario o fecha.'
					);
				}
			})
			.catch((error) => {
				if (error === 500) {
					customSB(
						'warning',
						'snackbar.error.validate.service.address.date.hour',
						'Debe seleccionar una dirección, una fecha y una hora para poder crear el servicio.'
					);
				} else {
					errorProcess();
				}
			});
	};

	const generateDateEndByCalendar = (data) => {
		return new Promise((resolve, reject) => {
			customSB(
				'warning',
				'snackbar.warning.validate.tech.exist.update',
				'Se esta validando que el servicio cumpla con los requisitos para ser actualizado, por favor espere...'
			);
			RequestUseCase.post(endpoints.services.generateEndDate, data)
				.then((response) => {
					const { idTipoServicio: serviceType = {} } = selectIds;
					const [serviceTypeId = '', serviceTypeCategory = ''] = (serviceType?.value || '').split(
						'-'
					);
					const ADDRESS = auxService.servicio.idDireccion.idDireccion;
					const SECTOR = auxService.servicio.idDireccion.idSector.idSector;
					const MUNICIPALITY = auxService.servicio.idDireccion.idSector.idMunicipio.idMunicipio;
					const TYPE_SERVICE = parseInt(serviceTypeId);
					const CATEGORY_SERVICE = parseInt(serviceTypeCategory);

					const DATA_ORIGINAL = data;

					const DATA_VALIDATED = {
						...data,
						idDireccion: {
							idDireccion: ADDRESS,
							idSector: {
								idSector: SECTOR,
								idMunicipio: {
									idMunicipio: MUNICIPALITY
								}
							}
						},
						idTipoServicio: {
							idTipoServicio: TYPE_SERVICE,
							idCategoriaServicio: {
								idCategoriaServicio: CATEGORY_SERVICE
							}
						},
						idHoraCita: {
							// idHora: formItem.idHoraCitaFin.idHora,
							// valor: dateformat(formItem.idHoraCitaFin.idHora, 'HH:MM').split(':').map(Number).reduce((horas, minutos) => horas + minutos / 60, 0),
							idHora: response.idHoraCita.idHora,
							valor: response.idHoraCita.valor
						},
						fechaCitaFin: response.fechaCitaFin.split('T')[0],
						idHoraCitaFin: {
							idHora: response.idHoraCitaFin.idHora,
							valor: response.idHoraCitaFin.valor
						}
					};
					validateServiceAgendaEvent(DATA_VALIDATED, DATA_ORIGINAL);
					resolve(true); // Resuelve con `true` si todo va bien
				})
				.catch((error) => {
					errorProcess();
					reject(error);
				});
		});
	};

	// Validate if the agenda have horary available
	const validateServiceAgendaEvent = (DATA, DATA_ORIGINAL) => {
		RequestUseCase.post(endpoints.services.validateCreateService, DATA)
			.then((response) => {
				if (response) {
					validateServiceRiskEvent(DATA, DATA_ORIGINAL);
					customSB(
						'success',
						'snackbar.success.validate.tech.exist',
						'Se encontró al menos un técnico disponible para este servicio, puede continuar con el proceso.'
					);
				} else {
					customSB(
						'error',
						'snackbar.error.validate.tech.exist',
						'No se encontró un técnico disponible para este servicio, seleccione otro horario o fecha.'
					);
				}
			})
			.catch((error) => {
				errorProcess();
				console.error(error);
			});
	};

	// Update service
	const putItemWhitTech = (data) => {
		Swal.fire({
			title: formatterText('alert.title.general', 'Atención, estás seguro de realizar esta acción'),
			text: formatterText('alert.description.update.general', 'Se va a editar el registro'),
			icon: 'question',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			showLoaderOnConfirm: true,
			cancelButtonColor: '#d33',
			confirmButtonText: formatterText('alert.button.confirm.general', 'Guardar cambios'),
			allowOutsideClick: false,
			cancelButtonText: formatterText('alert.button.cancel.general', 'Cancelar'),
			preConfirm: () => {
				return new Promise((resolve) => {
					RequestUseCase.post(endpoints.services.updateTypeService, data).catch((err) => {
						console.warn(err);
					});
					RequestUseCase.post(endpoints.services.updateDate, data)
						.then(() => {
							resolve(
								CustomAlert('confirm_msg', {
									icon: 'success',
									title: formatterText('alert.title.confirm.general', 'Operación exitosa'),
									text: formatterText(
										'alert.message.confirm.updated.general',
										'El registro se ha actualizado correctamente'
									),
									confirmButtonText: formatterText('alert.button.continue', 'Continuar'),
									allowOutsideClick: false
								})
							);
						})
						.catch((err) => {
							handleRequestError(err);
						});
				});
			}
		});
	};

	// Update service
	const putItem = (data) => {
		Swal.fire({
			title: formatterText('alert.title.general', 'Atención, estás seguro de realizar esta acción'),
			text: formatterText('alert.description.update.general', 'Se va a editar el registro'),
			icon: 'question',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			showLoaderOnConfirm: true,
			cancelButtonColor: '#d33',
			confirmButtonText: formatterText('alert.button.confirm.general', 'Guardar cambios'),
			allowOutsideClick: false,
			cancelButtonText: formatterText('alert.button.cancel.general', 'Cancelar'),
			preConfirm: () => {
				return new Promise((resolve) => {
					// this service create the item
					RequestUseCase.put(endpoints.services.updateService, data)
						.then(() => {
							resolve(
								CustomAlert('confirm_msg', {
									icon: 'success',
									title: formatterText('alert.title.confirm.general', 'Operación exitosa'),
									text: formatterText(
										'alert.message.confirm.updated.general',
										'El registro se ha actualizado correctamente'
									),
									confirmButtonText: formatterText('alert.button.continue', 'Continuar'),
									allowOutsideClick: false,
									executeFunction: () => navigate(paths.serviceModule)
								})
							);
						})
						.catch((err) => {
							handleRequestError(err);
						});
				});
			}
		});
	};

	// Get the clients for the selector
	const getAllClientsServices = () => {
		RequestUseCase.get(endpoints.clients.getAllClients)
			.then((res) => {
				// create new array
				const newArray = [
					{
						value: -1,
						label: 'Ninguno'
					}
				];
				// iterate response and get only the values that are active
				res.forEach((item) => {
					if (item.estado === 1) {
						newArray.push({
							value: item.idCliente,
							label: `${item.nombreRazonSocial} - ${item.identificacion}`
						});
					}
				});
				// set the values of the select
				setSearchSelected((prev) => ({
					...prev,
					clients: newArray
				}));
			})
			.catch((err) => {
				console.log(err);
			});
	};

	// Get the users for the selector
	const getAllUsersServices = () => {
		RequestUseCase.get(endpoints.services.getAllExternalUsers)
			.then((res) => {
				// create new array
				const newArray = [
					{
						value: -1,
						label: 'Ninguno'
					}
				];
				// iterate response and get only the values that are active
				res.forEach((item) => {
					if (item.estado === 1) {
						newArray.push({
							value: item.idUsuarioServicio,
							label: `${item.primerNombre} ${item.primerApellido} - ${item.telefono}`
						});
					}
				});
				// set the values of the select
				setSearchSelected((prev) => ({
					...prev,
					users: newArray
				}));
			})
			.catch((err) => {
				console.log(err);
			});
	};

	// Get the typeServices for the SelectorGrid
	const getAllTypeServicesServices = () => {
		RequestUseCase.get(endpoints.typeService.getAllTypeService).then((data) => {
			// create new array
			const newArray = [];
			// iterate response and get only the values that are active
			data.forEach((item) => {
				if (item.estado === 1) {
					newArray.push({
						value: `${item.idTipoServicio}-${item.idCategoriaServicio.idCategoriaServicio}`,
						label: `${item.nombre} - ${item.idCategoriaServicio.nombre}`
					});
				}
			});
			// set the values of the select
			setSearchSelected((prev) => ({
				...prev,
				typeService: newArray
			}));
		});
	};

	// Get the typeServices for the selector
	const getAllTypeServicesByTech = (idTech) => {
		// Set idTech to 0 if it is null
		RequestUseCase.get(endpoints.typeService.getAllTypeServiceByTech(idTech))
			.then((res) => {
				// create new array
				const newArray = [];
				// iterate response and get only the values that are active
				res.forEach((item) => {
					if (item.estado === 1) {
						newArray.push({
							value: `${item.idTipoServicio}-${item.idCategoriaServicio.idCategoriaServicio}`,
							label: `${item.nombre} - ${item.idCategoriaServicio.nombre}`
						});
					}
				});

				// set the values of the select
				setSearchSelected((prev) => ({
					...prev,
					typeServiceByTech: newArray
				}));
			})
			.catch((err) => {
				console.log(err);
			});
	};

	// Get the communicationMediaServices for the selector
	const getAllCommunicationMediaServices = () => {
		RequestUseCase.get(endpoints.services.getAllMedia)
			.then((res) => {
				// create new array
				const newArray = [];
				// iterate response and get only the values that are active
				res.forEach((item) => {
					if (item.estado === 1) {
						newArray.push({
							value: item.idMedioComunicacion,
							label: `${item.nombre} ${item.codigo}`
						});
					}
				});
				// set the values of the select
				setSearchSelected((prev) => ({
					...prev,
					communicationMedia: newArray
				}));
			})
			.catch((err) => {
				console.log(err);
			});
	};

	// Get the communicationChannel for the selector
	const getAllCommunicationChannelServices = () => {
		RequestUseCase.get(endpoints.services.getAllChannels)
			.then((res) => {
				// create new array
				const newArray = [];
				// iterate response and get only the values that are active
				res.forEach((item) => {
					if (item.estado === 1) {
						newArray.push({
							value: item.idCanalComunicacion,
							label: `${item.nombre} - ${item.codigo}`
						});
					}
				});
				// set the values of the select
				setSearchSelected((prev) => ({
					...prev,
					communicationChannel: newArray
				}));
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const deleteBillingUser = () => {
		if (idBillingService) {
			RequestUseCase.delete(endpoints.services.deleteBillingUserByService(idBillingService))
				.then(() => {
					successRemoveItem();
					getDataToUpdate();
				})
				.catch(console.log);
		}
	};
	// Get the timeMeeting for the selector
	const getAllTimeMeetingServices = () => {
		// create new array
		const newArray = [];
		// iterate response and get only the values that are active
		hours.forEach((item) => {
			newArray.push({
				value: item.idHora,
				label: item.hora
			});
		});
		// set the values of the select
		setSearchSelected((prev) => ({
			...prev,
			timeMeeting: newArray
		}));
	};

	const handleDate = (e) => {
		if (e.target) {
			setEventData({
				...eventData,
				[e.target.name]: e.target.value
			});
		} else {
			setTime(e);
			const HOUR_ID = searchHour(dateFormat(e, 'HH:MM'));
			setEventData({
				...eventData,
				idHoraCita: HOUR_ID
			});
		}
	};

	const [formItem, setFormItem] = useState([
		{
			descripcion: ''
		}
	]);

	const [formParent, setFormParent] = useState([]);
	const [formChildrens, setFormChildrens] = useState([]);

	const [idService, setIdService] = useState(0);

	const [showServiceData, setShowServiceData] = useState(true);

	const cloneService = () => {
		const STATE_SERVICE_BAN = {
			5: true,
			7: true,
			8: true
		};

		const RES = STATE_SERVICE_BAN[parseInt(idEstadoServicio)] || false;

		if (RES) {
			// alert that the service can not be cloned
			customSB(
				'warning',
				'snackbar.warning.clone.state.service',
				'No se puede clonar con el estado servicio actual.'
			);
		} else {
			const newService = {
				...auxService.servicio,
				idServicioPadre: auxService.servicio.idServicio,
				idServicio: null,
				fechaCreacion: null,
				fechaModificacion: null,
				usuarioCreacion: null,
				usuarioModificacion: null
			};

			// set the data in the context
			setServiceCloneData(newService);
			navigate(paths.duplicateService);
		}
	};

	const setEstadoServicio = (idES, idServicio) => {
		if (idEstadoServicio === 6 && !activityStart) {
			customSB(
				'warning',
				'snackbar.warning.update.state.service',
				'No se puede actualizar el estado del servicio, debido a que el estado "En Curso" tiene actividades iniciadas.'
			);
		} else {
			if (changeState) {
				const NEW_SERVICE = {
					idServicio: parseInt(idServicio),
					idEstadoServicio: idES,
					idTecnico: ppTech?.idtech,
					idUsuario: COOKIE_USER
				};

				RequestUseCase.put(endpoints.services.updateServiceState, NEW_SERVICE)
					.then((data) => {
						if (data.estado === false) {
							showSB('error', formatterText(data.mensaje));
						} else {
							// success data
							customSB(
								'success',
								'snackbar.success.update.state.service',
								'Estado del servicio actualizado correctamente'
							);
							setIdEstadoServicio(idES);
						}
					})
					.catch((error) => {
						handleRequestError(error);
					});
			} else {
				showPermissionDenied();
			}
		}
	};

	const handleDownload = () => {
		showDownloadFile();
		RequestUseCase.get(endpoints.services.downloadResume(id))
			.then((res) => {
				const extensionArchivo = 'pdf';
				const MimeType = 'pdf';
				const decoded = decodeBase64(res);
				functionToDownload(decoded, `${formItem.expediente}.${extensionArchivo}`, MimeType);
			})
			.catch(() => errorProcess());
	};

	const decodeBase64 = (base64) => {
		const byteCharacters = atob(base64);
		const byteNumbers = new Array(byteCharacters.length);
		for (let i = 0; i < byteCharacters.length; i++) {
			byteNumbers[i] = byteCharacters.charCodeAt(i);
		}
		const byteArray = new Uint8Array(byteNumbers);
		return byteArray;
	};

	const functionToDownload = (decoded, fileName, ext) => {
		const blob = new Blob([decoded], { type: `aplication/${ext}` });
		const link = document.createElement('a');
		link.href = window.URL.createObjectURL(blob);
		link.download = fileName;
		link.click();
	};

	const reOpenService = () => {
		const hashDesition = {
			8: 9,
			9: 6
		};
		setEstadoServicio(hashDesition[parseInt(idEstadoServicio)], idService);
	};

	const handlerTextDescription = (e) => {
		HandlerTextDescription(e, formItem, setFormItem);
	};

	const fetchDataService = async () => {
		getAllStatesService();
		await getBillingData();
		setLoadingProgress(true);
		getActivities();
		await getDataToUpdate();
		getAllClientsServices();
		getAllUsersServices();
		getAllTypeServicesServices();
		getAllCommunicationMediaServices();
		getAllCommunicationChannelServices();
		getAllTimeMeetingServices();
	};

	useEffect(() => {
		(async () => {
			await fetchDataService();
		})();
	}, []);

	useEffect(() => {
		(async () => {
			await fetchDataService();
		})();
	}, [id]);

	useEffect(() => {
		let techIdToSend = ppTech.idtech;
		// If ppTech.idtech is null, undefined, or -1, set techIdToSend to 0
		if (ppTech.idtech === null || ppTech.idtech === -1 || ppTech.idtech === undefined) {
			techIdToSend = 0;
		}
		(async () => {
			await getAllTypeServicesByTech(techIdToSend);
		})();
	}, [ppTech.idtech]);

	useEffect(() => {
		getActivitiesActive();
		activitiesNoTechsEndeds();
	}, [listActivities]);

	useEffect(() => {
		permissionsAccess();
	}, [permissions]);

	useEffect(() => {
		validationData();
	}, [access]);

	useEffect(() => {
		setShowInvoice(true);
	}, [ticket, expedient]);

	return {
		allStateService,
		canDeleteAnsNQR,
		canDeleteNQR,
		canReopen,
		changeStatusNQR,
		clientSelected,
		cloneService,
		customSB,
		deleteBillingUser,
		eventData,
		externalUserSelected,
		formChildrens,
		formItem,
		formParent,
		getAllUsersServices,
		getActivitiesActive,
		getDataToUpdate,
		handlerTextDescription,
		hashCanAnulate,
		hashReopenService,
		idEstadoServicio,
		idService,
		isDeleteTechMain,
		isGenClient,
		loadingProgress,
		manualSearch,
		myForm,
		navigate,
		now,
		onOpenQuotation,
		onOpenSearch,
		onCloseClient,
		onCloseExternalUser,
		onCloseQuotation,
		onCloseSearch,
		onToggleBillingUser,
		openBillingUser,
		openClient,
		openExternalUser,
		openSearch,
		permittedActions,
		ppTech,
		searchAutomatic,
		selectedSearch,
		selectIds,
		setBillingUserSelected,
		setCheckoutValidations,
		setEstadoServicio,
		setFormItem,
		setIsDeleteTechMain,
		setPpTech,
		setSelectIds,
		setShowServiceData,
		showInvoice,
		showServiceData,
		time,
		userSelected,
		valueSetter,
		handleSubmit,
		formatterText,
		DisplayProgress,
		addressData,
		reOpenService,
		checkoutValidations,
		activityStart,
		handleDate,
		billingUserSelected,
		handleDownload,
		activityNoTechEnd,
		ticket,
		expedient,
		quotation
	};
}
